import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import PropTypes from 'prop-types'
import { ModalBody } from 'reactstrap'
import TastyModal from './TastyModal'
import SmallPrint from './SmallPrint'
import externalLinks from 'utils/externalLinks'

const propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node,
  as: PropTypes.elementType
};

const defaultProps = {
  as: 'a',
  children: undefined
};

const StyledTastyModal = styled(TastyModal)`
  .modal-header {
    padding-top: 1rem;
  }

  .modal-title {
    @media (max-width: 448px) {
      align-items: flex-start;
      line-height: 1.625rem;

      span {
        background-position: 50% 22%;
      }
    }
  }
`;

const StyledModalBody = styled(ModalBody)`
  padding-top: 0;

  &&& {
    a {
      color: ${prop('theme.colors.primaryRed')};
      font-weight: 700;
      text-decoration: none;

      &:hover {
        color: ${prop('theme.colors.gray')};
        text-decoration: none;
      }
    }
  }

  ${SmallPrint} {
    font-style: normal;
    font-weight: 400;
  }
`

const TermsList = styled.ol`
  font-size: 0.75rem;
  font-weight: 400;
  padding-left: 1.5625rem;

  @media (min-width: ${prop('theme.breakpoints.xs')}) {
    font-size: 0.875rem;
  }

  li {
    padding-bottom: 0.3125rem;
  }
`;

function ReferralModal ({ href, children, as: LinkComponent, ...props }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = (e) => {
    e.preventDefault();
    setIsOpen(prev => !prev);
  };

  return (
    <>
      <LinkComponent href={href} onClick={toggle} {...props}>{children}</LinkComponent>

      <StyledTastyModal
        isOpen={isOpen}
        modalClassName='referral-modal'
        scrollable={true}
        size='lg'
        toggle={toggle}
        title='Referral Program Terms & Conditions'
      >
        <StyledModalBody>
          <TermsList>
            <li>
              Your Referral cannot be yourself, a related person(s) in the same
              household/mailing address (“Related Persons”) as you, international
              customers, individuals who already have a tastytrade account (including
              an IRA or joint account) or individuals that have “pre-registered”
              with tastytrade through
              {' '}
              <a
                href={externalLinks.tastylive}
                rel='noopener noreferrer'
                target='_blank'
              >
                www.tastylive.com
              </a>.
            </li>
            <li>
              Your Referral can only be referred once and will be counted as one
              referral credit regardless of the number of accounts that individual
              or his Related Persons open.
            </li>
            <li>
              Offer is not valid on tax-exempt trusts, 401K, other Retirement
              Accounts, or any account type not supported by tastytrade at the
              time your Referral opens an account.
            </li>
            <li>
              Offer is not transferable and not valid with internal transfers of
              existing tastytrade accounts.
            </li>
            <li>
              tastytrade reserves the right to disqualify participants for any
              reason at any time.
            </li>
            <li>
              tastytrade reserves the right to reverse the referral credit, in
              its sole discretion, if your Referral does not comply with the listed
              eligibility requirements, which includes maintaining a funded and
              valid brokerage account for at least 6 months, or any of the other
              terms of this Program and the tastytrade Terms of Use.
            </li>
            <li>
              The tastytrade Referral Program expires on 12/31/2021, and
              tastytrade reserves the right to revoke or extend this offer at
              any time.
            </li>
            <li>
              Referral credits will remain active for 6 months after the
              termination of the Referral Program, after which date they will
              expire and become worthless.
            </li>
            <li>
              Each referral credit on its own has no value and can only be used
              in combination with other credits obtained through the Referral
              Program to qualify for the Referral Items described above or cash
              equivalent. The Referral Program cannot be combined with any other
              offers, promotions or marketing referral agreements.
            </li>
            <li>
              Taxes related to tastytrade promotional items are your sole
              responsibility. Please consult a legal or tax advisor with any
              questions relating to the U.S. Tax Code.
            </li>
          </TermsList>
          <SmallPrint dark>
            <span className='font-weight-bold'>Your Personal Information:</span>
            {' '}
            Personal information collected in connection with this Program will
            be used in accordance with
            {' '}
            <a
              href={externalLinks.tastyworksPrivacyPolicy}
              rel='noopener noreferrer'
              target='_blank'
            >
              tastytrade’s Privacy Policy
            </a>.
          </SmallPrint>
          <SmallPrint dark>
            <span className='font-weight-bold'>
              Travel Associated with Trip to Chicago:
            </span>
            {' '}
            If you obtain 20 Referral Credits and redeem these credits for a trip
            to Chicago, you will receive transportation, and hotel accommodations
            for one night. All elements of the Trip are subject to availability
            and other restrictions and must be redeemed and/or completed between
            the departure date and the return date of the roundtrip air travel.
            Airport transfers, taxes, and fees; any baggage or upgrade fees; and
            other airline charges are not included and are your responsibility.
            Blackout periods and other restrictions may apply. You are responsible
            for obtaining proper documentation prior to travel, including, if
            necessary, passports and visas. tastytrade will not reimburse you for
            any costs or fees associated with changing air travel plans or failure
            to follow travel arrangements (e.g., missing a flight). tastytrade
            shall not be liable for any failure to operate, delay in operating,
            or accidents involving any travel, including but not limited to acts
            of God, governmental actions, fire, weather, war, civil commotion,
            riots, epidemics, quarantine, restrictions, terrorist events, air
            traffic control, strikes or labor disputes, inability to obtain fuel
            for the flight in question, or otherwise.
          </SmallPrint>
          <SmallPrint dark>
            <span className='font-weight-bold'>Referral Program Items:</span>
            {' '}
            No transfers, cash awards, or Referral Program Item substitutions are
            permitted, except by tastytrade, other than a cash deposit to your
            non-retirnment brokerage account (payment via check for retirement
            account holders). tastytrade reserves the right to substitute a Referral
            Program Item with one of comparable or greater value, at its sole
            discretion. Referral Program Items provided are subject to verification
            of eligibility, geographic location, and compliance with these Rules.
            You may be required to sign an affidavit of eligibility, liability
            release, and where legal, a publicity release (collectively, the
            “Affidavit”), and return same, properly executed, within seven (7)
            days of issuance of notification. Once the required information and
            official paperwork are received within the specified time period,
            verified, and receive final approval by tastytrade, the Referral
            Program Item or cash equivalent will be provided to you within sixty
            (60) days. tastytrade will not replace any lost or stolen Referral
            Program Items. tastytrade’s decisions are final in all respects of
            the Referral Program.
          </SmallPrint>
          <SmallPrint dark>
            The Referral Progarm cannot be combined with any other offers,
            promotions or marketing referral agreements.
          </SmallPrint>
          <SmallPrint dark>
            <span className='text-uppercase'>
              Referral Program items will be accepted "as is" without warranty
              of any kind, express or implied (including, without limitation, any
              implied warranty of merchantability or fitness for a particular
              purpose).
            </span>
            {' '}
            tastytrade has neither made nor is in any manner responsible or liable
            for any warranty, representation, or guarantee, express or implied,
            in fact or in law, relative to a Referral Program Item, including
            express warranties provided exclusively by a Referral Program Item
            manufacturer that may be sent along with the Referral Program Item.
            You will not be obligated to purchase any other products or services
            related to the Referral Program Item. You indemnify, release, and hold
            harmless tastytrade, its parent, subsidiaries, affiliates, retailers,
            and advertising and promotion agencies, and all of their respective
            officers, directors, members, managers, partners, shareholders,
            employees and agents (collectively, “Released Parties”) from losses
            or damages of any kind, including without limitation, death and bodily
            injury, resulting, in whole or in part, directly or indirectly, from
            the awarding, delivery, acceptance, use, misuse, or possession of the
            Referral Program Item resulting from participating in this Referral
            Program. You acknowledge and agree that tastytrade has not arranged
            for nor carries any insurance of any kind for your benefit or that
            of your heirs, executors, and administrators relative to your use of
            the Referral Program Item awarded to you; and that you are solely
            responsible for obtaining and paying for any insurance relative to
            the Referral Program Item provided.
          </SmallPrint>
          <SmallPrint dark>
            <span className='font-weight-bold'>General Conditions:</span>
            {' '}
            By participating in the Referral Program, you agree to release,
            discharge, indemnify and hold harmless tastytrade, its parent,
            subsidiaries, affiliates, retailers, and advertising and promotion
            agencies, and all of their respective officers, directors, members,
            managers, partners, shareholders, employees and agents (collectively,
            “Released Parties”) from any liability or damages which may arise out
            of participation in the Referral Program or out of the acceptance,
            use, misuse or possession of any Reward or products attained through
            this Referral Program. All applicable federal, state and local laws
            and regulations apply.
          </SmallPrint>
          <SmallPrint dark>
            Released Parties are not responsible for incorrect or inaccurate
            transcription of referral information, or for any human or printing
            error in these Rules or any promotional materials, technical malfunctions,
            lost/delayed data transmission, omission, interruption, deletion, defect,
            line failures of any telephone network, computer equipment, software,
            or any other error or malfunction, or any injury or damage to you or
            any other person’s computer related to or resulting from participation
            in the Referral Program, or for late, lost, stolen, illegible or
            misdirected referrals. Referrals that have been tampered with or altered
            are void. If, in tastytrade's opinion, there is any suspected or actual
            evidence of electronic or non-electronic tampering with any portion
            of this Referral Program, or if any difficulties compromise the
            administration, security, fairness, integrity, or proper conduct of
            the Referral Program, tastytrade reserves the right to void any
            referrals at issue.
          </SmallPrint>
          <SmallPrint dark>
            tastytrade also reserves the right at any time to modify, suspend,
            or cancel the Referral Program , and any changes will be effective
            immediately on notice. Notice will be given either on the tastytrade
            website or via e-mail. Your continued participation in the Referral
            Program after such notice will be deemed as an acceptance of such
            changes. You should review these Rules periodically to ensure familiarity
            with the most current version. You will always be able to tell when
            the version was last updated by checking the “Last Revised” date at
            the top of these Rules. All questions or disputes regarding the Referral
            Program, including without limitation, those involving eligibility,
            participation, fraud, and abuse will be resolved by tastytrade.
          </SmallPrint>
          <SmallPrint dark>
            <span className='font-weight-bold'>
              Disputes Related to the Referral Program:
            </span>
            {' '}
            The dispute resolution arbitration procedures set forth in your
            brokerage agreement with tastytrade governs this Referral Program.
            The Referral Program is conducted in English. Except where prohibited,
            all issues and questions concerning the construction, validity,
            interpretation, and enforceability of these Rules, or the rights and
            obligations of you or tastytrade in connection with this Referral
            Program, shall be governed by and construed in accordance with the
            laws of the State of Illinois, without giving effect to any choice
            of law or conflict of law rules (whether of the State of Illinois,
            or any other jurisdiction), which would cause the application of the
            laws of any jurisdiction other than in the State of Illinois.
          </SmallPrint>
          <SmallPrint dark>
            <span className='font-weight-bold'>Miscellaneous:</span>
            {' '}
            The invalidity or unenforceability of any provision of the Referral
            Program will not affect the validity or enforceability of any other
            provision. In the event that any provision of the Referral Program is
            determined to be invalid or otherwise unenforceable or illegal, the
            other provisions will remain in effect and will be construed in
            accordance with their terms as if the invalid or illegal provision
            were not contained herein. tastytrade's failure to enforce any term
            of the Referral Program will not constitute a waiver of that provision.
            You agree to waive any rights to claim ambiguity of these Rules.
            Headings are solely for convenience of reference and will not be deemed
            to affect in any manner the meaning or intent of the document or any
            provision hereof. In the event there is a discrepancy or inconsistency
            between disclosures or other statements contained in any Referral
            Program materials, privacy policy or terms of use on the tastytrade.com
            website and/or the terms and conditions of the Referral Program Rules,
            the Referral Program Rules shall prevail, govern, and control and the
            discrepancy will be resolved in tastytrade's sole and absolute discretion.
          </SmallPrint>
        </StyledModalBody>
      </StyledTastyModal>
    </>
  );
}

ReferralModal.propTypes = propTypes;
ReferralModal.defaultProps = defaultProps;

export default ReferralModal
