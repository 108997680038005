import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { ifProp, prop } from 'styled-tools'
import { Row, Col } from 'reactstrap'
import
  ScrollableAnchor,
  { configureAnchors }
from 'react-scrollable-anchor'
import Layout from 'components/Layout'
import SectionsWrapper from 'components/SectionsWrapper'
import Section from 'components/Section'
import Headline from 'components/Headline'
import SmallPrint from 'components/SmallPrint'
import ReferralModal from 'components/ReferralModal';
import SEO from 'components/SEO'
import externalLinks from 'utils/externalLinks'
import referItemFood from 'images/referItem_food.jpg'
import referItemApple from 'images/referItem_apple.jpg'
import referItemGhome from 'images/referItem_ghome.jpg'
import referItemTesla from 'images/referItem_tesla.jpg'
import referItemChicago from 'images/referItem_chicago.jpg'
import referItemInternet from 'images/referItem_internet.jpg'
import { useLayoutContext } from 'context/LayoutContext';

const ReferralWrapper = styled(SectionsWrapper)`
  background-color: ${prop('theme.colors.grayscaleBlue')};
  font-family: ${prop('theme.fonts.default')};
  margin-top: 6.5625rem;
  padding-bottom: 2rem;

  ${ifProp('$showAlert', css`
    margin-top: 9.5625rem;
  `)}

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 10.0625rem;

    ${ifProp('$showAlert', css`
      margin-top: 13.0625rem;
    `)}
  }

  hr {
    margin: 2rem 0;
  }

  &&& {
    a {
      color: ${prop('theme.colors.primaryRed')};
      font-weight: 700;
      text-decoration: none;

      &:hover {
        color: ${prop('theme.colors.gray')};
        text-decoration: none;
      }
    }
  }
`;

const HeadlineSection = styled(Section)`
  ${Headline} {
    text-align: left;
    padding-bottom: 0;
  }
`;

const CreditHeader = styled.h4`
  color: ${prop('theme.colors.primaryRed')};
  font-weight: 600;
  margin-bottom: 1rem;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    font-size: 1rem;
  }
`;

const StyledCol = styled(Col)`
  ${SmallPrint} {
    font-style: normal;
  }

  img {
    max-width: 100%;
  }
`;

const Subheader = styled.h5`
  font-size: 0.875rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    font-size: 1.125rem;
  }

  ${ifProp('bold', css`
    font-weight: 700;
    margin-bottom: 0.5rem;
  `)}

  ${ifProp('italic', css`
    font-weight: 300;
    font-style: italic;
    margin-bottom: 0.25rem;
  `)}
`;

const RulesSection = styled(Section)`
  font-size: 0.875rem;
  padding-bottom: 2rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    font-size: 1.125rem;
  }
`;

const RulesList = styled.ol`
  padding-left: 1.5625rem;

  li {
    padding-bottom: 0.875rem;
  }
`;

const RulesSublist = styled.ol`
  list-style-type: lower-alpha;
  margin-bottom: 0;
  padding-top: 0.875rem;

  li {
    padding-bottom: 0.875rem;

    &:last-child {
      padding-bottom: 0;
    }
  }
`;

const ReferralPage = () => {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  useEffect(() => {
    configureAnchors({offset: -150, scrollDuration: 600});
  })

  return (
    <Layout>
      <SEO title='Referral' />
      <ReferralWrapper noGutters $showAlert={showAlert}>
        <HeadlineSection withPadding>
          <Headline>
            Referral Program
          </Headline>
          <hr />
        </HeadlineSection>
        <Section withPadding>
          <p>
            The tastytrade Referral Program (the “Referral Program” or the
            “Program”) is our way of showing you appreciation for each and every
            person you introduce to tastytrade. To participate in the Program
            you&apos;ll need an active, valid, and funded securities brokerage account at
            tastytrade at the time you are deemed to have made the referral and
            at the time you redeem your credits.
          </p>
          <hr />
        </Section>
        <Section withPadding>
          <Subheader bold>Tell &apos;em what they&apos;ll win, Scott!</Subheader>
          <p>For each Qualified Referral you make, you will receive one Referral
          Credit (see <a href='#ruleFour'>rule 4</a> below). Credits earned can
          be redeemed for the following Referral Program Items or a cash deposit
          to your securities brokerage account for the maximum cash value indicated below
          <i>(max. cash value)</i>.</p>
          <Row>
            <StyledCol md={12} lg={6}>
              <Row className='mb-3 mb-lg-0'>
                <Col xs={5} sm={4} md={3} lg={5}>
                  <img src={referItemGhome} alt='Google Home' />
                </Col>
                <Col xs={7} sm={8} md={9} lg={7}>
                  <CreditHeader>2 Referral Credits</CreditHeader>
                  <Subheader bold>Google Home</Subheader>
                  <Subheader italic>(max. cash value of $150)</Subheader>
                </Col>
              </Row>
            </StyledCol>
            <StyledCol md={12} lg={6}>
              <Row>
                <Col xs={5} sm={4} md={3} lg={5}>
                  <img src={referItemFood} alt='Chicago-style hot dog' />
                </Col>
                <Col xs={7} sm={8} md={9} lg={7}>
                  <CreditHeader>5 Referral Credits</CreditHeader>
                  <Subheader bold>Chicago Food Package</Subheader>
                  <Subheader italic>(max. cash value of $500)</Subheader>
                  <SmallPrint>
                    Food Package details can be found
                    {' '}
                    <a
                      href={externalLinks.referralFoodPackage}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      here
                    </a>.
                  </SmallPrint>
                </Col>
              </Row>
            </StyledCol>
          </Row>
          <hr />
        </Section>
        <Section withPadding>
          <Row>
            <StyledCol md={12} lg={6}>
              <Row className='mb-3 mb-lg-0'>
                <Col xs={5} sm={4} md={3} lg={5}>
                  <img src={referItemInternet} alt='High speed internet' />
                </Col>
                <Col xs={7} sm={8} md={9} lg={7}>
                  <CreditHeader>10 Referral Credits</CreditHeader>
                  <Subheader bold>1 Year of High-Speed Internet Access</Subheader>
                  <Subheader italic>(max. cash value of $1,000)</Subheader>
                </Col>
              </Row>
            </StyledCol>
            <StyledCol md={12} lg={6}>
              <Row>
                <Col xs={5} sm={4} md={3} lg={5}>
                  <img src={referItemChicago} alt='Chicago skyline' />
                </Col>
                <Col xs={7} sm={8} md={9} lg={7}>
                  <CreditHeader>20 Referral Credits</CreditHeader>
                  <Subheader bold>Trip for 2 to Chicago</Subheader>
                  <Subheader italic>(max. cash value of $2,000)</Subheader>
                  <SmallPrint dark>
                    Trip for 2 to Chicago from anywhere in the continental U.S.
                    includes 1-night hotel stay, and dinner at a Michelin-rated
                    restaurant.
                  </SmallPrint>
                </Col>
              </Row>
            </StyledCol>
          </Row>
          <hr />
        </Section>
        <Section withPadding>
          <Row>
            <StyledCol md={12} lg={4} className='mb-3 mb-lg-0'>
              <CreditHeader>50 Referral Credits</CreditHeader>
              <Subheader bold>Apple Package</Subheader>
              <Subheader italic>(max. cash value of $5,000)</Subheader>
              <SmallPrint dark>
                Includes a MacBook Pro, an iPad, an iPhone, and an Apple Watch.
              </SmallPrint>
            </StyledCol>
            <StyledCol md={12} lg={8}>
              <img src={referItemApple} alt='Apple products' />
            </StyledCol>
          </Row>
          <hr />
        </Section>
        <Section withPadding>
          <Row className='mb-5'>
            <StyledCol md={12} lg={8} className='mb-3 mb-lg-0'>
              <img src={referItemTesla} alt='Tesla Model S' />
            </StyledCol>
            <StyledCol md={12} lg={4}>
              <CreditHeader>750 Referral Credits</CreditHeader>
              <Subheader bold>Tesla Model S</Subheader>
              <Subheader italic>(max. cash value of $50,000)</Subheader>
            </StyledCol>
          </Row>
          <SmallPrint dark>
            The maximum value indicated above is the dollar amount that
            tastytrade will allocate to your securities brokerage account in lieu of
            redeeming your Referral Credits for a specific Referral Program Item.
            The maximum dollar amount that tastytrade will allocate to a specific
            Referral Program Item redeemed is listed above as a maximum value and
            is based on the number of Referral Credits. tastytrade reserves the
            right to determine whether a specific Referral Program Item can be
            allocated to you based on your location or other factors.
          </SmallPrint>
          <SmallPrint dark>
            The above vendors are not affiliated with, do not sponsor, and have
            not endorsed this Referral Program.
          </SmallPrint>
          <hr />
        </Section>
        <RulesSection withPadding>
          <Subheader bold>
            How the tastytrade Referral Program works (the "Rules")
          </Subheader>
          <RulesList>
            <li>
              <a href={externalLinks.accountSignIn}>
                Sign in to tastytrade.com
              </a>
              {' '}
              and scroll down to find the “Generate  Referral Link” item found
              in the “Refer” panel on your home page. Click the item to generate
              your unique link.
            </li>
            <li>
              Share this unique referral link with relatives, friends, colleagues,
              and any other individual in the United States, age 18 or older,
              who does not currently have a securities brokerage account at tastytrade.
            </li>
            <li>
              The person you share your unique referral link with (“Referral”)
              will need to complete the following steps before you will earn a
              Referral Credit:
              <RulesSublist>
                <li>
                  Your Referral must come to tastytrade.com by clicking on your
                  unique referral link.
                </li>
                <li>
                  Your Referral must open a securities brokerage account at tastytrade,
                  which includes a valid email address, and must fund that
                  account with a minimum of
                  {' '}
                  <span className='font-weight-bold'>$2,000.00 USD</span>
                  {' '}
                  within
                  {' '}
                  <span className='font-weight-bold'>60 days</span>
                  {' '}
                  of opening the account
                  {' '}
                  <span className='font-italic'>
                    (see  “Additional Terms and Conditions" below for details).
                  </span>
                </li>
              </RulesSublist>
            </li>
            <ScrollableAnchor id='ruleFour'>
              <li>
                If Your Referral is eligible for a securities account with tastytrade, and
                meets all of the terms and conditions of the Referral Program
                (“Qualified Referral”), you will receive an update to the Referral
                Credits count on your profile page:
                <RulesSublist>
                  <li>
                    “Your Pending Credits” if a securities account is opened but not funded
                    with the minimum $2,000.00 USD.
                  </li>
                  <li>
                    “Your Redeemable Credits” when a securities account is opened and funded
                    with the minimum $2,000.00 USD.
                  </li>
                </RulesSublist>
              </li>
            </ScrollableAnchor>
            <li>
              If one of the Referral Program Thresholds listed above is met, you
              will receive an email notification with instructions on how to
              redeem your Referral Credits for the Referral Program Items listed
              above or how to request the cash equivalent of the qualified item.
              If you have an IRA account, payment will be made via check, otherwise
              a deposit will be made to your securities brokerage account. All Referral Credit
              redemptions, whether for a Referral Credit Item or cash equivalent,
              will be processed within 60 days from the date the redemption request
              is received. You do not need to redeem credits immediately, but
              can accumulate credits to redeem higher Referral Credit Items, as
              described above.
            </li>
            <li>
              Check and monitor your Qualified Referral Credits by signing in
              to tastytrade.com. This information will appear on your signed-in
              home page.
            </li>
          </RulesList>
          <ReferralModal href='#'>Additional Terms and Conditions</ReferralModal>
        </RulesSection>
      </ReferralWrapper>
    </Layout>
  )
}

export default ReferralPage;
